<template>
  <div class="footer">
    <div class="f-box">
      <div class="l">
        <img src="../assets/logo1.png" width="142px" height="54px" alt="" />
        <p>版权所有：海南科讯众联文化科技有限公司</p>
        <p>地址：海南省海口市美兰区国兴大道15A号全球贸易之窗大厦2405</p>
      </div>
      <!-- <div class="r">
        <p>
          <span class="img-box"></span>
          <span>公众号</span>
        </p>

        <p>
          <span class="img-box"></span>
          <span>APP下载</span>
        </p>
      </div> -->
    </div>

    <div class="txt-info">
      <p style="display: flex;
    align-items: center;"><img style="margin-right:5px;" src="../assets/icon.png"/>琼公网安备 46010802000918号</p>
      <p>琼ICP备 2021006930号-1</p>
      <p @click="showD">营业执照</p>
    </div>

    <div class="line"></div>

    <div class="dialog-bg" @click="hideD" v-if="showDg">
        <img src="../assets/营业执照.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            showDg: false
        }
    },
    methods: {
        hideD() {
            this.showDg = false;
        },
        showD() {
            this.showDg = true
        }
    }
};
</script>

<style scoped lang="less">
.footer {
  width: 100%;
  box-sizing: border-box;
  padding: 0 320px 0 312px;
  background: #000b20;
  position: relative;
  top: -4px;
  .f-box {
    display: flex;
    align-items: center;
    justify-content:center;
    .l {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-top: 14px;
      }
    }
    .r {
      display: flex;
      p {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 25px;
        .img-box {
          width: 100px;
          height: 100px;
          display: inline-block;
          background: rgba(255, 255, 255, 0.52);
          border: 4px solid rgba(255, 255, 255, 0.6588240000000001);
          border-radius: 0px;
        }
        span {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          margin-top: 18px;
        }
      }
    }
  }

  .txt-info {
    margin-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.52);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
      margin-right: 20px;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .line {
    position: absolute;
    bottom: -4px;
    height: 4px;
    background: #000b20;
    left: 0;
    right: 0;
  }

  .dialog-bg {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
  }
}
</style>