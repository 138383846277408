import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers = {
  'method': 'post',
  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
}
axios.defaults.timeout = 10000;
// axios.defaults.baseURL = 'http://39.98.71.25:8080';

Vue.use(VueAxios, axios)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
