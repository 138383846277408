import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/Home.vue')
  }, {
    path: '/home',
    name: 'index',
    component: () => import('../views/Home.vue')
  },{
    path: '/haven',
    name: 'haven',
    component: () => import('../views/Haven.vue')
  },{
    path: '/application',
    name: 'application',
    component: () => import('../views/Application.vue')
  },{
    path: '/industryChain',
    name: 'industryChain',
    component: () => import('../views/IndustryChain.vue')
  },{
    path: '/cooperationMethod',
    name: 'cooperationMethod',
    component: () => import('../views/CooperationMethod.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // to and from are both route objects. must call `next`.
  console.log(to);
  if (to.path === '/') {
    next({
      path: '/index'
    })
  } else {
    next()
  }
})

export default router
