<template>
  <div class="tab">
    <router-link to="Index" tag="div" class="logo"></router-link>
    <div class="route-list">
      <router-link to="index" tag="p" class="route-item"> 首页 </router-link>
      <router-link to="haven" tag="p" class="route-item"> 海文交 </router-link>
      <router-link to="industryChain" tag="p" class="route-item"> 产业链 </router-link>
      <router-link to="cooperationMethod" tag="p" class="route-item"> 合作方式 </router-link>
      <router-link to="application" tag="p" class="route-item"> 应用下载 </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tab",
};
</script>

<style scoped lang="less">
.tab {
  height: 10.0rem;
  background: #fafafa;
  width: 100%;
  box-sizing: border-box;
  padding: 0 22.3rem 0 29.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    width: 16.0rem;
    height: 5.4rem;
    cursor: pointer;
    background: url("../assets/logo.png") no-repeat center;
  }
  .route-item {
    height: 9.8rem;
    padding: 0 4.2rem;
    line-height: 10.0rem;
    display: inline-block;
    font-size: 1.8rem;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;
    color: #71719c;
    cursor: pointer;
    &.router-link-active {
      color: #fff;
      background: #000b20;
    }
  }
}
</style>