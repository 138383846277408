<template>
  <div id="app">
    <Tab />
    <div class="main">
      <router-view />
    </div>
    <Footer/>
  </div>
</template>

<script>
import Tab from "@/components/Tab.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Tab,
    Footer,
  },
};
</script>
<style lang="less">
html,
body {
  font-size: 10px !important;
  margin: 0;
  padding: 0;
}

* {
  margin: 0px;
  padding: 0px;
}
#app {
  min-width: 148rem;
}

.main {
  overflow-x: hidden;
}
</style>
